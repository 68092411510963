import React, { useState } from "react";

const AfterSaleForm = ({ setShowModal }) => {
  const apiUrl = "https://atmo.veribiscrm.com/api/web/updatedata";
  const AppointedUserId = 2482;

  const [service, setService] = useState("");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [kvkk, setKvkk] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const CustomerMail = mail;
    const CustomerTelephone = phone;
    const MusteriTalebi = message;
    const ServisTipi = service;
    let CustomerName = "";
    let CustomerSurname = "";

    const nameArr = name.split(" ");

    if (nameArr.length === 2) {
      CustomerName = nameArr[0];
      CustomerSurname = nameArr[1];
    } else {
      CustomerSurname = nameArr[nameArr.length - 1];
      CustomerName = nameArr.slice(0, nameArr.length - 1).join(" ");
    }

    const data = {
      CustomerName,
      CustomerSurname,
      CustomerMail,
      CustomerTelephone,
      MusteriTalebi,
      ServisTipi,
      AppointedUserId,
    };

    console.log("Data: ", data);

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ApiKey: process.env.REACT_APP_API_KEY,
        },
        method: "POST",
        body: JSON.stringify({
          Table: "Service",
          Data: data,
        }),
      });

      const result = await response.json();

      console.log("Result", result);

      if (result.Status == 0) {
        setName("");
        setMail("");
        setPhone("");
        setMessage("");
        setKvkk(false);

        alert("SatışSonrası Formu gönderildi");
      } else {
        alert("SatışSonrası Formu gönderilemedi");
      }
    } catch (err) {
      console.log("Error: ", err);
      alert("Sunucu Hatası");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
      <select
        id="service"
        name="service"
        value={service}
        onChange={(e) => setService(e.target.value)}
        className="p-2 border border-gray-200 rounded-md"
      >
        <option value="satis_sonrasi_hizmetler">
          -Satış Sonrası Hizmetlerimiz-
        </option>
        <option value="8064">Randevu</option>
        <option value="8065">İkame Araç</option>
        <option value="8066">Yol Yardımı</option>
        <option value="8067">Williams Car Care</option>
        <option value="8068">Kaporta</option>
        <option value="8069">Takas Hizmetleri</option>
        <option value="8070">Anlaşmalı Sigortalar</option>
        <option value="8071">Periyodik Bakım</option>
        <option value="8072">Yedek Parça</option>
        <option value="8073">Garanti</option>
        <option value="8074">Test Sürüşü</option>
      </select>
      <input
        type="text"
        placeholder="Adınız Soyadınız"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="p-2 border border-gray-200 rounded-md"
        required
      />
      <input
        type="email"
        placeholder="E-mail Adresiniz"
        value={mail}
        onChange={(e) => setMail(e.target.value)}
        className="p-2 border border-gray-200 rounded-md"
        required
      />
      <input
        type="tel"
        placeholder="Telefon Numaranız"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        className="p-2 border border-gray-200 rounded-md"
        required
      />
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Mesajınız"
        className="p-2 border border-gray-200 rounded-md"
        rows="4"
        required
      ></textarea>
      <div className="flex items-center space-x-2">
        <input type="checkbox" id="kvkk3" name="kvkk3" required />
        <label htmlFor="kvkk3" className="">
          <button
            onClick={() => setShowModal(true)}
            className="font-bold text-red-900"
            type="button"
          >
            KVKK Metni
          </button>
          'ni ve Gizlilik Politikanızı kabul ediyorum.
        </label>
      </div>
      <div className="flex items-center justify-center w-full">
        <button
          type="submit"
          className="w-40 p-1 text-white bg-red-900 rounded-md"
        >
          Gönder
        </button>
      </div>
    </form>
  );
};

export default AfterSaleForm;
